import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import Link from '~components/Link';
import Container from '~components/Container';
import RichText from '~components/RichText';
import VideoPopupCard from '~components/VideoPopupCard';
import BottomShapeDivider from '~components/ShapeDividers/BottomShapeDivider';

const useStyles = makeStyles(theme => ({
  root: props => ({
    backgroundColor: props.root_background_color
  }),
  container: {
    width: '100%',
    marginTop: '90px',
    marginBottom: '90px',
    color: '#FFFFFF',
    backgroundColor: props => props.background_color,
    padding: '102px 62px',
    borderRadius: '13px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '48px',
    [theme.breakpoints.down('md')]: {
      gap: '32px'
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '50px 30px',
      gap: '32px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '26px 30px',
      marginTop: '64px',
      marginBottom: '64px'
    }
  },
  title: props => ({
    color: props.primary_text_color,
    '& > *': {
      fontSize: '44px',
      fontWeight: 700,
      fontFamily: 'Inter',
      lineHeight: '113%',
      marginTop: 0,
      marginBottom: 0,
      [theme.breakpoints.down('xs')]: {
        fontSize: '32px'
      }
    },
    '& > * > strong': {
      color: props.bold_title_text_color
    },
    marginTop: 0,
    marginBottom: '12px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  }),
  description: {
    marginTop: 0,
    marginBottom: 0,
    color: props => props.primary_text_color,
    '& > *': {
      fontSize: '16px',
      fontFamily: 'Inter',
      lineHeight: '140%',
      maxWidth: '415px',
      marginTop: 0,
      marginBottom: 0,
      [theme.breakpoints.down('sm')]: {
        margin: 'auto'
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
        maxWidth: '307px'
      }
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  contentArea: {
    minWidth: '520px',
    [theme.breakpoints.down('md')]: {
      minWidth: '460px'
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 'unset',
      order: 1
    }
  },
  imageArea: {
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '40%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      order: 2
    }
  },
  ctaOutlineButton: props => ({
    cursor: 'pointer',
    color: props.cta_text_color,
    backgroundColor: props.cta_background_color,
    padding: '19px 32px',
    fontSize: 18,
    lineHeight: '18px',
    fontWeight: 700,
    borderRadius: 45,
    whiteSpace: 'nowrap',
    '&:hover': {
      textDecoration: 'none'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      borderRadius: 36,
      padding: '15px 20px'
    }
  }),
  thumbnail: {
    width: '100%',
    cursor: 'pointer',
    borderRadius: 40
  },
  buttonArea: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
    marginTop: '24px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  }
}));

const VideoOffer = ({
  title_formatted,
  description_formatted,
  video,
  root_background_color,
  background_color,
  primary_text_color,
  bold_title_text_color,
  cta_text,
  cta_url,
  cta_text_color,
  cta_background_color,
  bottom_shape_divider
}) => {
  const classes = useStyles({
    background_color,
    root_background_color,
    primary_text_color,
    bold_title_text_color,
    cta_text_color,
    cta_background_color
  });
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={classes.root}>
      <Container>
        <div className={classes.container} id="about">
          <div className={classes.contentArea}>
            <RichText
              externalClassName={classes.title}
              html={title_formatted?.html}
              verticalSpacing={0}
            />
            <RichText
              externalClassName={classes.description}
              html={description_formatted?.html}
              verticalSpacing={0}
            />
            {cta_url && (
              <div className={classes.buttonArea}>
                <Link to={cta_url} className={classes.ctaOutlineButton}>
                  {cta_text}
                </Link>
              </div>
            )}
          </div>
          <div className={classes.imageArea}>
            <VideoPopupCard
              url={video?.document?.data?.wistia_url?.url}
              thumbnailUrl={video?.document?.data?.cover_image?.url}
              nopopup={isSmallerScreen}
            />
          </div>
        </div>
      </Container>
      {/* Shape Divider */}
      {bottom_shape_divider?.url && (
        <BottomShapeDivider src={bottom_shape_divider.url} bgColor="transparent" flex />
      )}
    </div>
  );
};

VideoOffer.propTypes = {
  title_formatted: PropTypes.shape({
    html: PropTypes.string
  }),
  description_formatted: PropTypes.shape({
    html: PropTypes.string
  }),
  video: PropTypes.shape({
    document: PropTypes.shape({
      data: PropTypes.shape({
        wistia_url: PropTypes.shape({
          url: PropTypes.string
        }),
        cover_image: PropTypes.shape({
          url: PropTypes.string,
          alt: PropTypes.string
        })
      })
    })
  }),
  background_color: PropTypes.string,
  root_background_color: PropTypes.string,
  primary_text_color: PropTypes.string,
  bold_title_text_color: PropTypes.string,
  cta_text: PropTypes.string,
  cta_url: PropTypes.string,
  cta_text_color: PropTypes.string,
  cta_background_color: PropTypes.string,
  bottom_shape_divider: PropTypes.shape({
    url: PropTypes.string
  })
};

VideoOffer.defaultProps = {
  title_formatted: null,
  description_formatted: null,
  video: null,
  background_color: '#FFFFFF',
  root_background_color: '#FFFFFF',
  primary_text_color: '#000000',
  bold_title_text_color: '#000000',
  cta_text: '',
  cta_url: '',
  cta_text_color: '#FFFFFF',
  cta_background_color: '#000000',
  bottom_shape_divider: null
};

export default VideoOffer;
