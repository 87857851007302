import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MediaPlayer from '~components/media-player';

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end'
  },
  body: {
    padding: '8px 0px 0px',
    textAlign: 'center'
  },
  buttonStyle: {
    right: '4px',
    top: '4px',
    borderRadius: '50%',
    backgroundColor: '#EAEAEA',
    zIndex: 1,
    padding: 4,
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: '#E1E1E1'
    }
  },
  iconStyle: {
    fontSize: '14px',
    color: '#000000'
  }
}));

const config = {
  wistia: {
    options: {
      seo: false,
      videoFoam: true,
      playlistLinks: 'auto',
      playerColor: '#282828',
      resumable: false,
      playButton: false,
      autoPlay: true
    }
  }
};

const VideoDialog = ({ url, onClose, show }) => {
  const classes = useStyles();

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="video-popup"
      open={show}
      maxWidth="md"
      PaperProps={{
        style: { borderRadius: '10px', width: '100%', backgroundColor: '#000000' }
      }}
    >
      <div className={classes.header}>
        <IconButton aria-label="close" className={classes.buttonStyle} onClick={onClose}>
          <CloseIcon className={classes.iconStyle} />
        </IconButton>
      </div>
      <div className={classes.body}>
        {show && <MediaPlayer url={url} playing config={config} />}
      </div>
    </Dialog>
  );
};

VideoDialog.propTypes = {
  url: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

export default VideoDialog;
